import React, {useEffect, useState} from 'react';
import Card from '../Card';
import FunFact from '../FunFact';
import Hero from '../Hero';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import Cta from '../Cta';
import LogoList from '../LogoList';
import MovingText from '../MovingText';
import PortfolioSlider from '../Slider/PortfolioSlider';
import PostSlider from '../Slider/PostSlider';
import TestimonialSlider from '../Slider/TestimonialSlider';
import TeamSlider from '../Slider/TeamSlider';
import VideoModal from '../VideoModal';
import TimelineSlider from '../Slider/TimelineSlider';
import { pageTitle } from '../../helper';
import Hero6 from '../Hero/Hero6';
import MovingText2 from "../MovingText/MovingText2";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import {Icon} from "@iconify/react";
import Portfolio from "../Portfolio";
import IconBox from "../IconBox";
import Accordion from "../Accordion";


export default function Home() {
    pageTitle('RISEXI DIGITAL');
    const [itemShow, setItemShow] = useState(6);

    // Hero Social Links
    const heroSocialLinks = [
        {
            name: 'Behance',
            links: '/',
        },
        {
            name: 'Twitter',
            links: '/',
        },
    ];

    const serviceData1 = [
        {
            title: 'AI / Machine Learning',
        },
        {
            title: 'AI / Machine Learning',
        },
        {
            title: 'UI/UX Design',
        },
        {
            title: 'AI / Machine Learning',
        },
        {
            title: 'Marketing',
        },
        {
            title: 'Branding',
        },
    ];
    const serviceData2 = [
        {
            title: 'SEO',
            href: '/service/service-details',
        },
        {
            title: 'App design',
            href: '/service/service-details',
        },
        {
            title: 'React Developer',
            href: '/service/service-details',
        },
    ];

    const showcaseData = [
        {
            title: 'Your Vision <br/>Our Innovation',
            imgUrl: '/images/vrwhite.jpg',
            href: '',
        },
        {
            title: 'Think reality <br/>do positive',
            imgUrl: '/images/rasppi.jpg',
            href: '',
        },
        // {
        //     title: 'Think reality <br/>do positive',
        //     imgUrl: '/images/slider_3.jpeg',
        //     href: '/case-study/case-study-details',
        // },
        // {
        //     title: 'Think reality <br/>do positive',
        //     imgUrl: '/images/slider_4.jpeg',
        //     href: '/case-study/case-study-details',
        // },
    ];

    const portfolioData = [
        {
            title: 'FANWAGON X',
            subtitle: 'See Details',
            href: 'https://fanwagon.co',
            src: '/images/portfolio-fanwagon.jpg',
            category: 'ui_ux_design',
        },
        {
            title: 'Hi-Res 3D Model',
            subtitle: '',
            href: '',
            src: '/images/portfolio_12.jpeg',
            category: 'logo_design',
        },
        {
            title: 'Hi-Res 3D Model',
            subtitle: '',
            href: '',
            src: '/images/portfolio_13.jpeg',
            category: 'web_design',
        },
        {
            title: 'Hi-Res 3D Model',
            subtitle: '',
            href: '',
            src: '/images/portfolio_14.jpeg',
            category: 'mobile_apps',
        },
        {
            title: 'Hi-Res 3D Model',
            subtitle: '',
            href: '',
            src: '/images/portfolio_15.jpeg',
            category: 'ui_ux_design',
        },
        {
            title: 'Hi-Res 3D Model',
            subtitle: '',
            href: '',
            src: '/images/portfolio_16.jpeg',
            category: 'web_design',
        },
        {
            title: 'Hi-Res 3D Model',
            subtitle: '',
            href: '',
            src: '/images/portfolio_11.jpeg',
            category: 'ui_ux_design',
        }
    ];

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            {/* Start Hero Section */}
            <Hero6
                showcaseData={showcaseData}
            />
            {/* End Hero Section */}

            <Spacing lg="130" md="70" />
            <Div className="container">
                <h2 className="cs-font_50 cs-m0 text-center cs-line_height_4">
                    With over 30 years of combined development and engineering experience,
                    we've built products and powerful tools for startups and fortune-500 enterprises.
                </h2>
            </Div>
            <Spacing lg="50" md="30"/>

            {/* Start LogoList Section */}
            <Div className="container">
                <LogoList />
            </Div>

            {/* End LogoList Section */}

            {/* Start Service Section */}
            <Spacing lg="80" md="80" />
            <Div id="service">
                <Div className="container">
                    <Div className="row">
                        <Div className="col-xl-12">
                            <Div className="row">
                                {/* Placeholder */}
                                <Div className="col-lg-2 col-sm-6 cs-hidden_mobile"></Div>

                                {/* Cube 1 */}
                                <Div className="col-lg-2 col-sm-6">
                                    <Card
                                        title="Product Design"
                                        link=""
                                        src="/images/service_1.jpeg"
                                        alt="Service"
                                    />
                                    <Spacing lg="0" md="30" />
                                </Div>

                                {/* Placeholder */}
                                <Div className="col-lg-2 col-sm-6 cs-hidden_mobile"></Div>

                                {/* Cube 2 */}
                                <Div className="col-lg-2 col-sm-6">
                                    <Card
                                        title="API & SDK Development"
                                        link=""
                                        src="/images/service_2.jpeg"
                                        alt="Service"
                                    />
                                    <Spacing lg="0" md="30" />
                                </Div>

                                {/* Placeholder */}
                                <Div className="col-lg-2 col-sm-6 cs-hidden_mobile"></Div>

                                {/* Cube 3 */}
                                <Div className="col-lg-2 col-sm-6">
                                    <Card
                                        title="Product Strategy"
                                        link=""
                                        src="/images/strategy.jpg"
                                        alt="Service"
                                    />
                                    <Spacing lg="0" md="30" />
                                </Div>

                                {/* Cube 4 */}
                                <Div className="col-lg-2 col-sm-6">
                                    <Card
                                        title="VR/AR Experiences"
                                        link=""
                                        src="/images/service_3.jpeg"
                                        alt="Service"
                                    />
                                    <Spacing lg="0" md="30" />
                                </Div>

                                {/* Placeholder */}
                                <Div className="col-lg-2 col-sm-6 cs-hidden_mobile"></Div>

                                {/* Cube 5 */}
                                <Div className="col-lg-2 col-sm-6">
                                    <Card
                                        title="Web & App Development"
                                        link=""
                                        src="/images/3dmodeling.jpg"
                                        alt="Service"
                                    />
                                    <Spacing lg="0" md="30" />
                                </Div>

                                {/* Placeholder */}
                                <Div className="col-lg-2 col-sm-6 cs-hidden_mobile"></Div>

                                {/* Cube 6 */}
                                <Div className="col-lg-2 col-sm-6">
                                    <Card
                                        title="3D Modeling & Animation"
                                        link="s"
                                        src="/images/service_4.jpeg"
                                        alt="Service"
                                    />
                                    <Spacing lg="0" md="30" />
                                </Div>

                                {/* Placeholder */}
                                <Div className="col-lg-2 col-sm-6 cs-hidden_mobile"></Div>
                            </Div>
                        </Div>
                    </Div>
                </Div>
                {/* Start Moving Text Section */}
                {/*<Spacing lg="50" md="30" />*/}
                {/*<MovingText2 data={serviceData1} />*/}
                {/*<MovingText2 reverseDirection data={serviceData2} />*/}
                {/* End Moving Text Section */}
            </Div>
            {/* End Service Section */}

            {/* Start Projects Section */}
            <Spacing lg="145" md="80" />
            <Div className="container" id="creations">
                <SectionHeading
                    title="Recent Creations"
                    subtitle="Where experience meets excellence"
                    variant="cs-style1 text-center"
                />
                <Spacing lg="90" md="45" />
                <Div className="row">
                    {portfolioData.slice(0, itemShow).map((item, index) => (
                        <Div
                            className={`${
                                index === 0 || index === 3 || index === 4
                                    ? 'col-lg-8'
                                    : 'col-lg-4'
                            }`}
                            key={index}
                        >
                            <Portfolio
                                title={item.title}
                                subtitle={item.subtitle}
                                href={item.href}
                                src={item.src}
                                variant="cs-style1 cs-type1"
                            />
                            <Spacing lg="25" md="25" />
                        </Div>
                    ))}
                </Div>

                <Div className="text-center">
                    {portfolioData.length <= itemShow ? (
                        ''
                    ) : (
                        <>
                            <Spacing lg="65" md="40" />
                            <span
                                className="cs-text_btn"
                            >
              </span>
                        </>
                    )}
                </Div>
            </Div>
            {/* End Projects Section */}


            {/* Start Testimonial Section */}
            <Spacing lg="70" md="70" />
            <TestimonialSlider />
            {/* End Testimonial Section */}

            {/*/!* Start Video Block Section *!/*/}
            {/*<Spacing lg="70" md="70" />*/}
            {/*<Div className="container">*/}
            {/*    <h2 className="cs-font_50 cs-m0 text-center cs-line_height_4">*/}
            {/*        Our process is rooted in trust and transparency, enabling us to craft*/}
            {/*        exceptional products and services with our partners.*/}
            {/*    </h2>*/}

            {/*</Div>*/}
            {/*/!* End Video Block Section *!/*/}

            <Spacing lg='145' md='80'/>
            <Div className="container">
                <SectionHeading
                    title='Our process'
                    subtitle=''
                    variant='cs-style1 text-center'
                />
                <Spacing lg='90' md='45'/>
                <Div className="row">
                    <Div className="col-lg-4">
                        <IconBox
                            icon='/images/icons/service_icon_1.svg'
                            title='Project Initiation'
                            subtitle='Dive deep into your goals and visions with our meticulous requirement gathering process. By understanding the scope of the project, we ensure that our proposal aligns perfectly with your objectives, setting the foundation for a successful collaboration.'
                        />
                        <Spacing lg='30' md='30'/>
                    </Div>
                    <Div className="col-lg-4">
                        <IconBox
                            icon='/images/icons/service_icon_2.svg'
                            title='Design & Development'
                            subtitle='Our iterative approach begins with detailed wireframing, transitioning into tangible prototypes. The development phase harnesses the latest technologies, and rigorous testing ensures the final product is both robust and user-friendly.'
                        />
                        <Spacing lg='30' md='30'/>
                    </Div>
                    <Div className="col-lg-4">
                        <IconBox
                            icon='/images/icons/service_icon_3.svg'
                            title='Launch'
                            subtitle='The culmination of our collaborative efforts results in a seamless product launch. With our comprehensive launch strategy, we ensure that your digital solution is market-ready, set to thrive, and positioned to make a lasting impact, fostering consistent growth.'
                        />
                        <Spacing lg='30' md='30'/>
                    </Div>
                </Div>
            </Div>

            {/*<Spacing lg='145' md='80'/>*/}
            {/*<Div className="container cs-shape_wrap_4">*/}
            {/*    <Div className="cs-shape_4"></Div>*/}
            {/*    <Div className="cs-shape_4"></Div>*/}
            {/*    <Div className="container">*/}
            {/*        <Div className="row">*/}
            {/*            <Div className="col-xl-5 col-lg-6">*/}
            {/*                <SectionHeading*/}
            {/*                    title='Some pre questions and answers'*/}
            {/*                    subtitle='FAQ’s'*/}
            {/*                />*/}
            {/*                <Spacing lg='90' md='45'/>*/}
            {/*            </Div>*/}
            {/*            <Div className="col-lg-6 offset-xl-1">*/}
            {/*                <Accordion/>*/}
            {/*            </Div>*/}
            {/*        </Div>*/}
            {/*    </Div>*/}
            {/*</Div>*/}

            {/* Start CTA Section */}
            <Spacing lg="50" md="30" />

            <Div className="container" id="reach-us">
                <Cta
                    title="Let’s build <br />something <i>memorable</i> together"
                    btnText="Schedule Meeting"
                    btnLink="https://calendly.com/risexi_digital/discovery_call"
                    bgSrc="/images/cta_bg.jpeg"
                />
            </Div>
            {/* End CTA Section */}

        </>
    );
}
