import ReCAPTCHA from "react-google-recaptcha";
import React, {useEffect, useState} from 'react';
import {Link, NavLink} from 'react-router-dom';
import SocialWidget from '../Widget/SocialWidget';
import Newsletter from '../Widget/Newsletter';
import './header.scss';
import ContactInfoWidget from '../Widget/ContactInfoWidget';
import Div from '../Div';
import DropDown from './DropDown';
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import {Icon} from "@iconify/react";

export default function Header({variant}) {
    const [isSticky, setIsSticky] = useState(false);
    const [sideHeaderToggle, setSideHeaderToggle] = useState(false);
    const [mobileToggle, setMobileToggle] = useState(false);

    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        projectType: '',
        company: '',
        message: ''
    });
    const [formError, setFormError] = useState('');

    const [recaptchaValue, setRecaptchaValue] = useState(null);

    const handleRecaptchaChange = value => {
        setRecaptchaValue(value);
    };
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const {fullName, email, projectType, company, message} = formData;
        if (!fullName || !email || !projectType || !company || !message) {
            setFormError('Please fill out all the required fields.');
            return;
        }
        // TODO: Handle form submission to Firebase function
    };


    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 0) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        });
    }, []);

    return (
        <>
            <header
                className={`cs-site_header cs-style1 text-uppercase ${
                    variant ? variant : ''
                } cs-sticky_header ${isSticky ? 'cs-sticky_header_active' : ''}`}
            >
                <Div className="cs-main_header">
                    <Div className="container">
                        <Div className="cs-main_header_in">
                            <Div className="cs-main_header_left">
                                <Link className="cs-site_branding" to="/">
                                    <img src="/images/logo.png" alt="Logo"/>
                                </Link>
                            </Div>
                            <Div className="cs-main_header_center">
                                <Div className="cs-nav cs-primary_font cs-medium">
                                    <ul
                                        className="cs-nav_list"
                                        style={{display: `${mobileToggle ? 'block' : 'none'}`}}
                                    >
                                        <li>
                                            <NavLink
                                                to="#"
                                                onClick={(e) => {
                                                    e.preventDefault();  // Prevent the default link behavior
                                                    document.getElementById('service').scrollIntoView({behavior: 'smooth'});
                                                }}
                                            >
                                                About
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="#" onClick={(e) => {
                                                e.preventDefault();
                                                document.getElementById('creations').scrollIntoView({behavior: 'smooth'});
                                            }}>
                                                Creations
                                            </NavLink>
                                        </li>
                                        <li>
                                            <NavLink to="#" onClick={(e) => {
                                                e.preventDefault();
                                                document.getElementById('reach-us').scrollIntoView({behavior: 'smooth'});
                                            }}>
                                                Reach Us
                                            </NavLink>
                                        </li>
                                    </ul>
                                    <span
                                        className={
                                            mobileToggle
                                                ? 'cs-munu_toggle cs-toggle_active'
                                                : 'cs-munu_toggle'
                                        }
                                        onClick={() => setMobileToggle(!mobileToggle)}
                                    >
                    <span></span>
                  </span>
                                </Div>
                            </Div>
                            <Div className="cs-main_header_right">
                                <Div className="cs-toolbox">
                  <span
                      className="cs-icon_btn"
                      onClick={() => setSideHeaderToggle(!sideHeaderToggle)}
                  >
                    <span className="cs-icon_btn_in">
                      <span/>
                      <span/>
                      <span/>
                      <span/>
                    </span>
                  </span>
                                </Div>
                            </Div>
                        </Div>
                    </Div>
                </Div>
            </header>

            <Div
                className={
                    sideHeaderToggle ? 'cs-side_header active' : 'cs-side_header'
                }
            >
                <button
                    className="cs-close"
                    onClick={() => setSideHeaderToggle(!sideHeaderToggle)}
                />
                <Div
                    className="cs-side_header_overlay"
                    onClick={() => setSideHeaderToggle(!sideHeaderToggle)}
                />
                <Div className="cs-side_header_in">
                    <Div className="cs-side_header_shape"/>
                    <Link className="cs-site_branding" to="/">
                        <img src="/images/logo.png" alt="Logo"/>
                    </Link>
                    <Div className="cs-side_header_box">
                        <h2 className="cs-side_header_heading">
                            Do you have a project in your mind? Schedule a time to chat.
                        </h2>
                    </Div>

                    <Div className="container">
                        <Div className="row">
                            <a href="https://calendly.com/risexi_digital/discovery_call" target="_blank"
                               rel="noopener noreferrer">
                                <button className="cs-btn cs-style1">
                                    <span>Schedule Meeting </span>
                                    <Icon icon="bi:arrow-right"/>
                                </button>
                            </a>
                        </Div>
                    </Div>

                    <Spacing lg="50" md="30"/>

                    <Div className="cs-side_header_box">
                        <h2 className="cs-side_header_heading">
                            Or, send us a message.
                        </h2>
                    </Div>

                    <Div className="container">
                        <Div className="row">

                            <Div className="col-lg-12">
                                <form action="#" className="row">
                                    <Div className="col-sm-6">
                                        <label className="cs-primary_color">Full Name*</label>
                                        <input type="text" name="fullName" className="cs-form_field"
                                               value={formData.fullName} onChange={handleInputChange}/>
                                        <Spacing lg="20" md="20"/>
                                    </Div>
                                    <Div className="col-sm-6">
                                        <label className="cs-primary_color">Email*</label>
                                        <input type="text" name="email" className="cs-form_field" value={formData.email}
                                               onChange={handleInputChange}/>
                                        <Spacing lg="20" md="20"/>
                                    </Div>
                                    <Div className="col-sm-6">
                                        <label className="cs-primary_color">Project Type*</label>
                                        <input type="text" name="projectType" className="cs-form_field"
                                               value={formData.projectType} onChange={handleInputChange}/>
                                        <Spacing lg="20" md="20"/>
                                    </Div>
                                    <Div className="col-sm-6">
                                        <label className="cs-primary_color">Company*</label>
                                        <input type="text" name="company" className="cs-form_field"
                                               value={formData.company} onChange={handleInputChange}/>
                                        <Spacing lg="20" md="20"/>
                                    </Div>
                                    <Div className="col-sm-12">
                                        <label className="cs-primary_color">Message*</label>
                                        <textarea name="message" cols="30" rows="4" className="cs-form_field"
                                                  value={formData.message} onChange={handleInputChange}></textarea>
                                        <Spacing lg="25" md="25"/>
                                    </Div>
                                    <Div className="col-sm-12">
                                        <ReCAPTCHA
                                            sitekey="6LfJhZgoAAAAAM_ZSC4Li9mA5fq5GJBF5ZBuwA-4"
                                            onChange={handleRecaptchaChange}
                                        />
                                        <button className="cs-btn cs-style1">
                                            <span>Send Message</span>
                                            <Icon icon="bi:arrow-right"/>
                                        </button>
                                    </Div>
                                </form>
                            </Div>
                        </Div>
                    </Div>
                    <Div className="cs-side_header_box">
                    </Div>
                </Div>
            </Div>
        </>
    );
}
