import React from 'react';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';

export default function Button({ btnLink, btnText, variant, icon }) {
  // Check if the link is external
  const isExternalLink = btnLink && (btnLink.startsWith('http://') || btnLink.startsWith('https://'));

  if (isExternalLink) {
    return (
        <a
            href={btnLink}
            target="_blank"
            rel="noopener noreferrer"
            className={variant ? `cs-text_btn ${variant}` : 'cs-text_btn'}
        >
          <span>{btnText}</span>
          {icon ? icon : <Icon icon="bi:arrow-right" />}
        </a>
    );
  } else {
    return (
        <Link
            to={btnLink}
            className={variant ? `cs-text_btn ${variant}` : 'cs-text_btn'}
        >
          <span>{btnText}</span>
          {icon ? icon : <Icon icon="bi:arrow-right" />}
        </Link>
    );
  }
}
