import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Pagination, Navigation } from 'swiper';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import parse from 'html-react-parser';
import Div from '../Div';

export default function FullScreenHorizontalSlider({ data }) {
  return (
    <Div className="cs-swiper_arrow_style_1">
      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        mousewheel={false}
        pagination={{
          clickable: true,
        }}
        speed={1000}
        loop={true}
        modules={[Mousewheel, Pagination, Navigation]}
        className="mySwiper"
      >
        {data.map((item, index) => (
          <SwiperSlide key={index}>
            <Div
              className="cs-hero cs-style4 cs-bg"
              style={{ backgroundImage: `url(${item.imgUrl})` }}
            >
              <Link to={item.href} className="cs-hero_link" />
              <Div className="cs-hero_text">
                <h2 className="cs-hero_title">{parse(item.title)}</h2>
              </Div>
            </Div>
          </SwiperSlide>
        ))}
      </Swiper>
    </Div>
  );
}
