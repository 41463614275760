import React from 'react'
import Div from '../Div'
import './logolist.scss'

export default function LogoList() {
  const partnerLogos = [
    {
      src: '/images/verizon-logo.png',
      alt:'Verizon'
    },
    {
      src: '/images/audioshake-logo.png',
      alt:'Audioshake'
    },
    {
      src: '/images/siriusxm.png',
      alt:'Sirius XM'
    },
    {
      src: '/images/ovo-small.png',
      alt:'OVO Sound'
    },
    {
      src: '/images/fanwagon-logo.png',
      alt:'Partner'
    }
  ]
  return (
    <Div className="cs-partner_logo_wrap">
      {partnerLogos.map((partnerLogo, index)=><div className="cs-partner_logo" key={index}><img src={partnerLogo.src} alt={partnerLogo.alt} /></div>)}
    </Div>
  )
}
